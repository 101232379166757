import React from "react"
import styles from "./index.module.css";
import { Alert } from 'reactstrap';
import  blog_larg_img  from "../../images/blog_larg_img.png";
import  back_arrow  from "../../images/back_arrow.svg";
import Img from 'gatsby-image'
import { getBlogComments, postBlogComments } from "../../services/blogComments";
import { formatDate } from "../../common/gFunctions";
import {  Link, navigate } from "gatsby"
import BlogPlaceholder from "../../gatsby-images/blog-placeholder";
import ReactHtmlParser from 'react-html-parser';
import HomeTopGraphic from "../../gatsby-images/home-top-graphic";

export const isBrowser = () => typeof window !== "undefined"

const commentSize = 10;
const { getBlogPath } = require('../../gFunctions')

class BlogTag extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
      comments: [],
      newComment: {
          author_email: '',
          author_name: '',
          content: ''
      },
      index: 1,
      isLoad: true,
      validationmsg: "",
      visible: false,
      isPublish: false,
      defaultLength: 110,
    };
 
  }

  publishComment = (event) => {
    event.preventDefault();
    if(this.state.newComment.author_name === "" )
    {
        this.setState({ validationmsg: "Please enter your name.", visible: true })
    }
    else if(this.state.newComment.author_email === "")
    {
        this.setState({ validationmsg: "Please enter your email.", visible: true  })
    } else if(this.state.newComment.content === "")
    {
        this.setState({ validationmsg: "Please enter your comment.", visible: true  })
    } else {
        this.setState({visible: false, isPublish: true})

        postBlogComments(this.props.post.wordpress_id,this.state.newComment)
            .then(({error,response}) => {
                if (error.status === 400) {
                    alert("Invalid email");
                } else if (error.status === 201) {
                    this.setState({
                        comments: [
                            {
                                ...response,
                                unapproved: true
                            },
                            ...this.state.comments
                        ],
                        newComment: {
                            author_email: '',
                            author_name: '',
                            content: ''
                        },
                        validationmsg: "Comment Successfully",
                        visible: true,
                        isPublish: false
                    });

                    setTimeout(() => {
                        this.setState({ visible: false})
                    },1000)
                }
            })
    }
  }

  componentDidMount() {
      if (this.props.post) {
        getBlogComments(this.props.post.wordpress_id)
            .then((data) => {
                this.setState({ comments: data.response, isLoad: false })
            })
      }
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  back = (e) => {
      e.preventDefault();
      if (this.props.location && this.props.location.state && this.props.location.state.backLink) {
          window.history.back();
        //   navigate(this.props.location.state.backLink || '/blog');
      } else {
          navigate('/blog');
      }
//    window.history.back();
  }

  onChange = (e, type, isNumber=false) => {
    const value = isNumber ? +e.target.value : (e.target.type === 'checkbox' ? e.target.checked : e.target.value);
    if (type !== null) {
      this.setState({
        [type]: {
            ...this.state[type],
            [e.target.name]: value
        }
      })
    } else {
      
    }
  }
  
  seeMore = () => {
    this.setState((state) => ({
        index: state.index + 1,
        isLoad: true
    }),
    () => {
        getBlogComments(this.props.post.wordpress_id, this.state.index)
        .then((data) => {
            var prevComments = this.state.comments;
            var comments = [...prevComments, ...data.response]
            this.setState({ 
                comments,
                isLoad: false
             })
        })
    })
  }
 
  shareFacebook = () => {
     window.open('https://www.facebook.com/sharer/sharer.php?u='+this.props.location.href,'popup','width=600,height=800');
     return false;
  }
  shareLinkedin = () => {
    window.open('https://www.linkedin.com/sharing/share-offsite/?url='+this.props.location.href,'popup','width=600,height=800');
    return false;
  }
  shareTwitter = () => {
    window.open('http://www.twitter.com/share?url='+this.props.location.href,'popup','width=600,height=500');
    return false;
  }

  render() {
   const {post, relatedPost, location={}} = this.props;

   var content = post.content.replace("<table","<div class='overflow-blog-table'><table");
   content = content.replace("</table>","</table></div>")

   var backText = "View more blog posts";
   if (location.state && location.state.backLink) {
       backText = "Back";
   }
   var tags = post.tags ? post.tags : [];
   var fluid = (post.featured_media ? ( post.featured_media.localFile.childImageSharp ? post.featured_media.localFile.childImageSharp.fluid : blog_larg_img ): blog_larg_img);
    return (
      <>
        <div className={[styles.blog_main_section,'blog_main_section'].join(' ')}>
          <div className={[styles.top_banner_section].join(' ')}>
              
          </div>
          
        <div className={styles.mpr_blog_list_section}>
            <div className="container">
                <div className={["row", "justify-content-md-center"].join(" ")}>
                    <div className={["col-xl-10", "col-lg-10"].join(" ")}>
                        <div className={[styles.blog_list,'pb-md-5', 'mb-md-5', 'mb-4', 'pb-4'].join(' ')}>
                            <div className={[styles.mpr_main_title].join(' ')}>
                                <span className={[styles.back_link,'d-md-block d-none', "mb-3"].join(' ')} onClick={this.back}><img className="mr-2" src={back_arrow} alt="Back"/> <span className={styles.backText}>{backText}</span></span>
                                <h2>{ReactHtmlParser(post.title)}</h2>
                            </div>

                            <div className={["blog_featured_img", "mb-md-5", "mb-4", "mt-3",styles.blog_featured_img].join(" ")}>
                                {!post.featured_media ? 
                                    <HomeTopGraphic/>
                                :
                                <div className={styles.blogImageContainer}><Img className={styles.blogImage} imgStyle={{'objectFit': 'contain','maxHeight': '450px'}} fluid={{ ...fluid, aspectRatio: 0}} /></div>
                                }
                            </div>
                            <div className={styles.blog_text}>
                                <div className={["row", "justify-content-md-center"].join(" ")}>
                                    <div className={[styles.socialShareContainer,'d-md-block', 'd-none'].join(' ')}>
                                        <div className={[styles.sticky_social_links].join(' ')}>
                                            <label>Share</label>
                                            <div className={styles.social_share_links}>
                                                <a onClick={()=>this.shareFacebook()} target="popup"><i className={["fa", "fa-facebook"].join(" ")} aria-hidden="true"></i></a>
                                                <a onClick={()=>this.shareTwitter()}><i className={["fa", "fa-twitter"].join(" ")} aria-hidden="true"></i></a>
                                                <a onClick={()=>this.shareLinkedin()}><i className={["fa", "fa-linkedin"].join(" ")} aria-hidden="true"></i></a>
                                            </div>           
                                        </div>
                                    </div>
                                    <div className={["col-md-10",styles.blogContentContainer].join(" ")}>
                                        <div>{ReactHtmlParser(content)}</div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className={["row", "justify-content-md-center"].join(" ")}>
                                <div className={[styles.blog_footer,'col-md-10'].join(' ')}>
                                    <div className={["row","align-items-start","mt-4"].join(" ")}>
                                        <div className={["col-xl-9", "col-md-8", "mb-md-0", "mb-5", styles.blogDate].join(" ")}>
                                            {formatDate(post.date)}                                            
                                        </div>
                                    </div>
                                    <div className={["row", "align-items-end"].join(" ")}>
                                        <div className={["col-xl-9", "col-md-8", "mb-md-0", "mb-5"].join(" ")}>
                                            <div className={styles.tags}>
                                               {
                                                    tags.map((tag)=>(
                                                         <a key={tag.id}>{tag.name}</a>
                                                    ))
                                               }
                                               
                                            </div>
                                        </div>
                                        <div className={["col-xl-3", "col-md-4", "mb-md-0", "mb-4"].join(" ")}>
                                            <div className={[styles.footer_social_icons,'float-md-right', 'float-none'].join(' ')}>
                                                <label>Share</label>
                                                <div className={styles.social_share_links}>
                                                    {/* <a href={"https://www.facebook.com/sharer/sharer.php?u="+this.props.location.href} target="_blank"><i className={["fa", "fa-facebook"].join(" ")} aria-hidden="true"></i></a> */}
                                                    <a onClick={()=>this.shareFacebook()} target="popup"><i className={["fa", "fa-facebook"].join(" ")} aria-hidden="true"></i></a> 
                                                    <a onClick={()=>this.shareTwitter()}><i className={["fa", "fa-twitter"].join(" ")} aria-hidden="true"></i></a>
                                                    <a onClick={()=>this.shareLinkedin()}><i className={["fa", "fa-linkedin"].join(" ")} aria-hidden="true"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={[styles.related_articles_section].join(' ')}>
            {
            relatedPost &&
                <div className="container">
                    <div className={["row", "justify-content-md-center"].join(" ")}>
                        <div className={["col-xl-10", "col-lg-10"].join(" ")}>
                            <div className={[styles.related_title,'mb-md-5', 'mb-4'].join(' ')}>
                                <h2>Related Articles</h2>
                            </div>
                            <div className={styles.related_articles}>
                                <div className="row">
                                    {
                                        relatedPost.edges.map(({node},index)=>(
                                            <div className="col-md-4" key={index}>
                                                <div className={[styles.imageContainer].join(" ")}>
                                                    {/* { !node.featured_media ? 
                                                        <img className={[styles.relatePostImage].join(" ")} src={blog_img1} />
                                                    :
                                                        <Img className={styles.relatePostImage} imgStyle={{'objectFit': 'contain'}} fluid={node.featured_media ? ( node.featured_media.localFile.childImageSharp ? node.featured_media.localFile.childImageSharp.fluid : blog_img1 ): blog_img1 } />
                                                    } */}
                                                    {
                                                        node.featured_media && node.featured_media.localFile && node.featured_media.localFile && node.featured_media.localFile.childImageSharp
                                                        ?
                                                        <Img fluid={node.featured_media.localFile.childImageSharp.fluid} />
                                                        :
                                                        <BlogPlaceholder/>
                                                    }
                                            </div>
                                            
                                                <div className={[styles.related_list,'mb-md-0', 'mb-5'].join(' ')}>
                                                    <h5 className={[styles.related_title,'mb-4'].join(' ')}><Link to={getBlogPath(node.path)} crossOrigin="anonymous">{ReactHtmlParser(node.title)}</Link></h5>
                                                    <div className={styles.related_text}>
                                                        <div>{ReactHtmlParser(readMoreRowText(node.excerpt, this))}</div>
                                                    </div>
                                                    {node.excerpt.length > this.state.defaultLength ? <div> <Link to={getBlogPath(node.path)} className={styles.read}>Read more</Link> </div> : ""}
                                                    <div className={styles.author}>
                                                        {/* 21 October, 2019 */}
                                                        {formatDate(node.date)} 
                                                    </div>
                                                </div>
                                            </div> 
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
        <div className={[styles.comments_section,'pt-md-5', 'pb-md-5', 'pt-5', 'pb-5'].join(' ')}>
            <div className="container">
                <div className={["row", "justify-content-md-center"].join(" ")}>
                    <div className="col-lg-6">
                        <div className={[styles.comments_title,'mb-5'].join(' ')}>
                            <h2>Comments</h2>
                        </div>
                        <Alert color="warning" fade={true} isOpen={this.state.visible} toggle={this.onDismiss}>
                            {this.state.validationmsg}
                        </Alert>
                        <div className={[styles.comment_form,'mb-5'].join(' ')}>
                            <form>
                                <div className="form-group">
                                    <input type="text" className={[styles.formControl,'form-control', 'shadow-sm '].join(' ')} value={this.state.newComment.author_name} name="author_name" onChange={(e)=>{this.onChange(e,'newComment')}} placeholder="Name*"/>
                                </div>
                                <div className="form-group">
                                    <input type="text" className={[styles.formControl,'form-control', 'shadow-sm '].join(' ')} value={this.state.newComment.author_email} name="author_email" onChange={(e)=>{this.onChange(e,'newComment')}} placeholder="Email*"/>
                                </div>
                                <div className={styles.bottom_field}>
                                <div className="form-group">
                                    <textarea className={[styles.formControl,'form-control', 'shadow-sm'].join(' ')} value={this.state.newComment.content} name="content" onChange={(e)=>{this.onChange(e,'newComment')}} placeholder="Write a response" rows="5"></textarea>
                                </div>
                                            <button type="submit" className={[styles.btn_publish,'btn', 'btn-primary', 'rounded', 'btn-sm',  styles.loader].join(' ')} onClick={this.publishComment} disabled={this.state.isPublish}>{this.state.isPublish && <i className={["fa", "fa-spinner", "fa-spin", "mr-1", "text-center"].join(" ")}></i>}Publish</button>
                                </div>
                            </form>
                        </div>
                        <div className={styles.comment_list_section}>
                            {
                                this.state.comments.map(node=>(
                                    <div key={node.id} className={[styles.comment_list,'shadow-sm', 'rounded', 'mb-3'].join(' ')}>
                                        <div className="mb-2">
                                            <h6>{node.author_name}<span className={styles.unapprovedLabel}>{node.unapproved ? ' (Pending Approval)' : ''}</span></h6>
                                            <span className={styles.comment_date}>{formatDate(node.date)}</span>
                                        </div>
                                        <div>{ReactHtmlParser(node.content.rendered)}</div>
                                    </div>
                                ))
                            }
                            <div className={["col", "text-center", "mb-5", "mt-4"].join(" ")}>
                                {
                                    this.state.comments.length > 0 ?
                                    (this.state.comments.length === commentSize * this.state.index || this.state.isLoad )  && <button className={["btn", "btn-primary", "rounded", "pt-md-3", "pb-md-3", styles.loader].join(" ")} onClick={this.seeMore} disabled={this.state.isLoad}>{ this.state.isLoad && <i className={["fa", "fa-spinner", "fa-spin", "mr-1", "text-center"].join(" ")}></i>} See more </button> 
                                    :
                                    (this.state.isLoad && <button className={["btn", "btn-primary", "rounded", "pt-md-3", "pb-md-3", styles.loader].join(" ")} disabled={true}>{ this.state.isLoad && <i className={["fa", "fa-spinner", "fa-spin", "mr-1", "text-center"].join(" ")}></i>} Loading Comments </button> )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        </div>
      </>
    );
  }
}
const readMoreRowText = (text, context) => {
    
    var displayStringLength = 0; 
    var sentanceCompleteLength = 0;
   
    displayStringLength =  (text.length > context.state.defaultLength  ? context.state.defaultLength  : text.length) ;
    sentanceCompleteLength =  text.indexOf(".")
    var addDot = sentanceCompleteLength > 0 ? (sentanceCompleteLength > displayStringLength ? true : false) : false;
    sentanceCompleteLength = sentanceCompleteLength > 0 ? sentanceCompleteLength : displayStringLength;
    displayStringLength =   sentanceCompleteLength > context.state.defaultLength  ? displayStringLength : sentanceCompleteLength + 1;
    text = text.substring(0, displayStringLength)  + (addDot ? "..." : "");
    return text;
}

export default BlogTag 