import { processResponse, createUrlQueryParams } from "../common/gFunctions";

const WPAPI = "https://wp.mprofit.in/wp-json/wp/v2";
const WPCOMMENTS = WPAPI + "/comments"

export const getBlogComments = (post, page = 1, showAll = false) => {
    var url = WPCOMMENTS+"?post="+post + (showAll ? "" : "&page=" + page);
    return fetch(url,{
        method: 'GET',
    }).then((data) => {
        return data;
    })
    .then(processResponse);
}

export const postBlogComments = (post, body) => {
    var params = {
        post
    }
    
    return fetch(createUrlQueryParams(WPCOMMENTS,params),{
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    }).then(processResponse);
}