module.exports = {
    getBlogPath: function(path) {
        return '/blog'+path.replace('/mwpapi','');
    },
    getHelpTopicPath: function(slug, categorySlug) {
        var path = '/help';
        if (categorySlug && categorySlug.slice(0,1) != '/') {
            categorySlug = '/' + categorySlug;
        }
        path += categorySlug;
        if (slug && slug.slice(0,1) != '/') {
            slug = '/' + slug;
        }
        path += slug;
        return path + "/";
    },
    getHelpCategoryPath: function(slug) {
        var path = '/help';
        if (slug && slug.slice(0,1) != '/') {
            slug = '/' + slug;
        }
        path += slug;
        return path + "/";
    }
}