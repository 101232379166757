import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/flayout"
import BlogDetail from "../components/blogDetail"
import { strip } from "../common/gFunctions";

export class BlogPost extends React.Component {

  render() {
    const post = this.props.data.wordpressPost;
    const relatedPost = this.props.data.allWordpressPost;
    return (
      <Layout darkHeader={true} seoFields={{...seoFields,title:strip("<p>"+post.title+"</p>")}}>
        <BlogDetail post={post} comments={[]} relatedPost={relatedPost} location={this.props.location}/>
      </Layout>
    )
  }
}

const seoFields = {
  description: "",
  // lang,
  // meta,
  // keywords,
  title: "Blog Post" 
}

export const pageQuery = graphql`
  query($slug: String!,$categoryName: String!) {
    wordpressPost(slug: { eq: $slug}) {
      id,
      title,
      date,
      content,
      wordpress_id,
      tags{
        id,
        name
      },
      featured_media{ 
        localFile {
          childImageSharp {
            fluid(maxWidth: 920, quality: 80) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
    allWordpressPost(
      sort: { fields: [date], order: DESC }
      limit: 3
      filter: {categories: {elemMatch: {name: { eq: $categoryName }}}, slug: { ne: $slug}}
    ) {
      edges {
        node {
          title
          excerpt
          slug
          content
          date
          path
          categories {
            name
            id
          }
        featured_media{ 
          localFile {
              childImageSharp {
                fluid(maxWidth: 200) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
         }
        }
      }
    }
    

  }
`

export default BlogPost;